.loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #00000073;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-header {
    cursor: pointer;
    position: relative;
    padding-right: 30px;
}
.card-header.opened:after {
    transition: 500ms ease;
    right: 17px;
    transform: translateY(-50%) rotate(180deg);
}
.card-header:after {
    content: url('../../assets/img/css_img/arrow-down-sign-to-navigate.svg');
    transition: 300ms ease;
    width: 17px;
    height: 17px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
