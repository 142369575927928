.responsiveTable td.pivoted:nth-child(8),
.responsiveTable td.pivoted:nth-child(7),
.responsiveTable td.pivoted:nth-child(9) {
}
.mobile-table .responsiveTable td.pivoted {
    border-bottom: 1px solid #0000002b !important;
    padding-bottom: 3px;
    min-height: 50px;
}
.mobile-table .responsiveTable td.pivoted:nth-last-child(1) {
    border-bottom: none !important;
}
